import React from "react";
import { graphql } from "gatsby";

import { useTranslations } from "../hooks/translations";
import SEO from "../components/seo";

export const query = graphql`
  query NotFoundQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        notFound
      }
      ...LayoutTranslations
    }
  }
`;

const NotFoundPage = () => {
  const t = useTranslations("pages.notFound");

  return (
    <section>
      <div className="bp-container">
        <SEO title={t.title} robots="noindex" />
        <h1>{t.heading}</h1>
        <p>{t.message}</p>
      </div>
    </section>
  );
};

export default NotFoundPage;
